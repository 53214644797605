import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    line: {
      fill: "none",
      stroke: theme.palette.common.black,
      strokeWidth: 4,
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
    fill: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      fill: theme.palette.common.black,
    },
    error: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      fill: theme.palette.error.main,
    },
  };
});

export function TechnicalError(): JSX.Element {
  const styles = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="110px"
      height="80px"
      viewBox="0 11 110 80"
      version="1.1"
    >
      <path
        className={styles.error}
        d="M93,57c9.4,0,17,7.6,17,17c0,9.4-7.6,17-17,17c-9.4,0-17-7.6-17-17C76,64.6,83.6,57,93,57z   M93,79c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S94.1,79,93,79z M93,65c-1.1,0-2,0.9-2,2l0,0v8c0,1.1,0.9,2,2,2s2-0.9,2-2l0,0v-8  C95,65.9,94.1,65,93,65z"
      />
      <path
        className={styles.line}
        d="M72,89H10c-4.4,0-8-3.6-8-8V21c0-4.4,3.6-8,8-8h84c4.4,0,8,3.6,8,8v32l0,0"
      />
      <path
        className={styles.fill}
        d="M56,43h28c1.1,0,2,0.9,2,2l0,0c0,1.1-0.9,2-2,2H56c-1.1,0-2-0.9-2-2l0,0C54,43.9,54.9,43,56,43z  "
      />
      <path
        className={styles.fill}
        d="M56,53h14c1.1,0,2,0.9,2,2l0,0c0,1.1-0.9,2-2,2H56c-1.1,0-2-0.9-2-2l0,0  C54,53.9,54.9,53,56,53z"
      />
      <path
        className={styles.fill}
        d="M73.2,67L56,67c-1.1,0-2-0.9-2-2s0.9-2,2-2l19.1,0C74.3,64.3,73.7,65.6,73.2,67z"
      />
      <path
        className={styles.fill}
        d="M56,75h4c1.1,0,2,0.9,2,2l0,0c0,1.1-0.9,2-2,2h-4c-1.1,0-2-0.9-2-2l0,0  C54,75.9,54.9,75,56,75z"
      />
      <path
        className={styles.fill}
        d="M16,75h2c1.1,0,2,0.9,2,2l0,0c0,1.1-0.9,2-2,2h-2c-1.1,0-2-0.9-2-2l0,0  C14,75.9,14.9,75,16,75z"
      />
      <path
        className={styles.fill}
        d="M26,75h2c1.1,0,2,0.9,2,2l0,0c0,1.1-0.9,2-2,2h-2c-1.1,0-2-0.9-2-2l0,0  C24,75.9,24.9,75,26,75z"
      />
      <line className={styles.line} x1="2" y1="31" x2="102" y2="31" />
      <circle className={styles.fill} cx="16" cy="22" r="2" />
      <circle className={styles.fill} cx="24" cy="22" r="2" />
      <circle className={styles.fill} cx="32" cy="22" r="2" />
      <path
        className={styles.line}
        d="M18,43h24c1.1,0,2,0.9,2,2v20c0,1.1-0.9,2-2,2H18c-1.1,0-2-0.9-2-2V45C16,43.9,16.9,43,18,43  z"
      />
    </svg>
  );
}
