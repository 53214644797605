import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { Link } from "../Link/Composed";
import { TechnicalError } from "../ColoredIcons/TechnicalError";

import { Button } from "src/components/Input/Button";
import TillDialog from "src/components/TillDialog";
import { SUPPORT_EMAIL } from "src/const/externalURIs";

const useStyles = makeStyles((theme) => ({
  textStyle: {
    color: theme.palette.text.hint,
  },
}));

export function ErrorBoundryModal({
  isOpen,
}: {
  isOpen: boolean;
}): JSX.Element {
  const { textStyle } = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <TillDialog isOpen={isOpen} disableMobileFullscreen>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        <Grid item justifyContent="center" alignItems="center">
          <TechnicalError />
        </Grid>

        <Grid item>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            spacing={1}
          >
            <Grid item>
              <Typography component="h2" variant="h1">
                Something went wrong
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" className={textStyle} align="center">
                Sorry, we are having a technical issue, please try refreshing
                the page.
                {isMobile ? " " : <br />}
                If the error persists contact{" "}
                <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Button
            type="button"
            variant="contained"
            color="primary"
            fullWidth={isMobile}
            onClick={handleRefresh}
          >
            Refresh now
          </Button>
        </Grid>
      </Grid>
    </TillDialog>
  );
}
